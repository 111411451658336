<template>
    <div class="pc-container">
        <BACK />
        <div v-if="type == 0" class="main">
            <div class="box1">{{$t('Account.AddGoogleAuth')}}</div>
            <div class="box2">
                <div class="title">{{$t('Account.Step1')}}</div>
                <div v-html="$t('Account.Step1Desc')" class="desc"></div>
                <div class="btns">
                    <div class="btn">
                        <img class="icon" src="@/assets/images/pc/account/apple.png" />
                        App Store
                    </div>
                    <div class="btn">
                        <img class="icon" src="@/assets/images/pc/account/google.png" />
                        Google Play
                    </div>
                </div>
            </div>
            <div class="box3">
                <div class="title">{{$t('Account.Step2')}}</div>
                <div v-html="$t('Account.Step2Desc')" class="desc"></div>
                <div class="ewm-box">
                    <qrcode :value="ewm" :size="160" />
                    <div class="address">{{key}}</div>
                </div>
                <div class="copy-btn" @click="copy(key)">{{$t('Account.CopyKey')}}</div>
            </div>
            <div class="box4">
                <div class="title">{{$t('Account.Step3')}}</div>
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item prop="google_code">
                        <el-input v-model="form.google_code" type="number" :placeholder="$t('Account.EnterGoogleVerificationCode')"></el-input>
                    </el-form-item>
                </el-form>
                <el-button :loading="loading" class="submit-btn" @click="submit">{{$t('Common.Confirm')}}</el-button>
            </div>
        </div>
        <div v-else class="form-box">
            <div class="form-title">{{$t('Account.RemoveGoogleAuth')}}</div>
            <div class="warning-tips">
                <i class="el-icon-warning"></i>
                {{$t('Account.RemoveGoogleAuthTips')}}
            </div>
            <el-form ref="form" :model="form" :rules="rules">
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item prop="google_code">
                        <el-input v-model="form.google_code" type="number" :placeholder="$t('Account.EnterGoogleVerificationCode')"></el-input>
                    </el-form-item>
                </el-form>
            </el-form>
            <el-button :loading="loading" class="submit-btn" @click="deleteGoogle">{{$t('Common.Confirm')}}</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            BACK: () => import('@/components/back.vue'),
            qrcode: () => import('@/components/qrcode.vue')
        },
        data() {
            return {
                key: '',
                ewm: '',
                form: {
                    google_code: ''
                },
                rules: {
                    google_code: [
                        {
                            required: true,
                            message: this.$t('Account.PleaseEnterGoogleVerificationCode'),
                            trigger: 'blur'
                        }
                    ]
                },
                loading: false
            }
        },
        created() {
            this.getKey()
        },
        computed: {
            type() {
                return Number(this.$route.params.type)
            }
        },
        methods: {
            copy(text) {
                let input = document.createElement("input")
                input.value = text
                document.body.appendChild(input)
                input.select()
                input.setSelectionRange(0, input.value.length)
                document.execCommand('Copy')
                document.body.removeChild(input)
                this.$message({
                    message: this.$t('Common.CopySuccessfully'),
                    type: 'success',
                    duration: 1500
                })
            },
            
            getKey() {
                this.$api.getGoogle().then(res => {
                    this.key = res.data.secret || ''
                    this.ewm = res.data.url || ''
                })
            },
            
            submit() {
                this.$refs.form.validate((valid) => {
                    // console.log(valid)
                    if(valid) {
                        this.loading = true
                        this.$api.bindGoogle({
                            secret: this.key,
                            google_code: this.form.google_code
                        }).then(res => {
                            this.loading = false
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                            setTimeout(() => {
                                this.$router.go(-1)
                            },1000)
                        }).catch(err => {
                            // console.log(err)
                            this.loading = false
                        })
                    }
                })
            },
            
            deleteGoogle() {
                this.$refs.form.validate((valid) => {
                    // console.log(valid)
                    if(valid) {
                        this.loading = true
                        this.$api.removeGoogle({
                            google_code: this.form.google_code
                        }).then(res => {
                            this.loading = false
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                            setTimeout(() => {
                                this.$router.go(-1)
                            },1000)
                        }).catch(err => {
                            // console.log(err)
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;
        box-sizing: border-box;
        padding: 25px $box-padding 50px;
        background-color: #f6f6f6;
        
        .main {
            width: 800px;
            margin: 0 auto;
            
            .box1 {
                width: 100%;
                box-sizing: border-box;
                padding: 50px 32px;
                margin-bottom: 25px;
                font-size: 20px;
                font-weight: bold;
                color: #000000;
                text-align: center;
                word-wrap: break-word;
                background-color: #FFFFFF;
            }
            
            .box2 {
                width: 100%;
                box-sizing: border-box;
                padding: 50px 32px;
                margin-bottom: 25px;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 40px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .desc {
                    width: 100%;
                    margin-bottom: 40px;
                    font-size: 15px;
                    color: #6D6F7E;
                    // text-align: center;
                    word-wrap: break-word;
                }
                
                .btns {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    
                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        max-width: 48%;
                        height: 56px;
                        box-sizing: border-box;
                        padding: 0 40px;
                        border-radius: 56px;
                        font-size: 20px;
                        font-weight: bold;
                        color: #FFFFFF;
                        white-space: nowrap;
                        background-color: #F08834;
                        cursor: pointer;
                        
                        &:not(:last-child) {
                            margin-right: 40px;
                        }
                        
                        .icon {
                            min-width: 20px;
                            max-width: 20px;
                            margin-right: 16px;
                        }
                    }
                }
            }
            
            .box3 {
                width: 100%;
                box-sizing: border-box;
                padding: 50px 32px;
                margin-bottom: 25px;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 40px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .desc {
                    width: 100%;
                    font-size: 15px;
                    color: #6D6F7E;
                    // text-align: center;
                    word-wrap: break-word;
                }
                
                .ewm-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    margin: 40px 0 60px;
                    
                    .address {
                        max-width: 100%;
                        margin-top: 16px;
                        font-size: 15px;
                        color: #000000;
                        text-align: center;
                        word-break: break-all;
                    }
                }
                
                .copy-btn {
                    width: 100%;
                    height: 56px;
                    line-height: 56px;
                    box-sizing: border-box;
                    border-radius: 56px;
                    font-size: 15px;
                    color: #FFFFFF;
                    text-align: center;
                    background-color: #F08834;
                    cursor: pointer;
                }
            }
            
            .box4 {
                width: 100%;
                box-sizing: border-box;
                padding: 50px 32px;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 40px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                /deep/ .el-input__inner {
                    height: 50px;
                    line-height: 50px;
                    padding: 0 25px;
                    border-radius: 50px;
                    border: 1px solid #D3D3D3;
                    font-size: 15px;
                    color: #000000;
                    background-color: #FFFFFF;
                }
                
                .submit-btn {
                    width: 100%;
                    height: 56px;
                    box-sizing: border-box;
                    margin: 60px 0 0;
                    padding: 0;
                    border: 0;
                    border-radius: 56px;
                    font-size: 15px;
                    color: #FFFFFF;
                    text-align: center;
                    background-color: #F08834;
                    cursor: pointer;
                }
            }
        }
        
        .form-box {
            width: 600px;
            box-sizing: border-box;
            padding: 40px 40px 50px;
            margin: 0 auto;
            border-radius: 26px;
            background-color: #FFFFFF;
        
            .form-title {
                width: 100%;
                margin-bottom: 32px;
                font-size: 20px;
                font-weight: bold;
                color: #000000;
                text-align: center;
                word-wrap: break-word;
            }
        
            .warning-tips {
                display: flex;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                padding: 20px 25px;
                margin-bottom: 24px;
                border-radius: 100px;
                font-size: 15px;
                color: #f28e3d;
                word-wrap: break-word;
                background-color: #fffae6;
        
                .el-icon-warning {
                    margin-right: 6px;
                    font-size: 20px;
                    color: #f28e3d;
                    white-space: nowrap;
                }
            }
        
            /deep/ .el-form-item {
                margin-bottom: 24px;
            }
        
            /deep/ .el-input__inner {
                height: 50px;
                line-height: 50px;
                padding: 0 25px;
                border-radius: 50px;
                border: 1px solid #D3D3D3;
                font-size: 15px;
                color: #000000;
                background-color: #FFFFFF;
            }
        
            .submit-btn {
                width: 100%;
                height: 56px;
                box-sizing: border-box;
                margin: 60px 0 0;
                padding: 0;
                border: 0;
                border-radius: 56px;
                font-size: 15px;
                color: #FFFFFF;
                text-align: center;
                background-color: #F08834;
                cursor: pointer;
            }
        }
    }
</style>
